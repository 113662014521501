import * as React from 'react';
import Box from '../components/Box';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Metadata from '../components/Metadata';
import Summary from '../components/Summary';
import Menu from '../components/Menu';

const IndexPage = ({ location }) => {
  const aboutBlurb = 'Get to know the human that is this human.';

  const backgroundBlurb = 'Check out my qualifications and experience.';

  const projectsBlurb =
    'Check out some of the things I have been working on ' +
    'lately for examples of my work.';

  const loading = (location) => {
    console.log('Hash:', location);
    if (location.hash === '#loaded') return { delay: 0, typed: null };
    return { delay: 4600, typed: 'node helloIanGoodnight.js' };
  };

  const { delay, typed } = loading(location);

  return (
    <Layout footerDelay={delay + 2400}>
      <Metadata title="Hello world" />
      <Hero initialDelay={delay} typed={typed} />
      <Summary delay={delay + 1200}>
        <p>
          3+ years experience developing full-stack Javascript web applications
          utilizing React, MongoDB, Express, and Node.js as well as JAMstack
          frameworks such as Gatsby and Nextjs. Experienced building and
          integrating RESTful APIs with everything from Google Sheets to
          specialized microservices. 3+ years desktop/application support in an
          enterprise environment. In my spare time, I enjoy hacking with Perl
          and studying cyber-security.
        </p>
      </Summary>
      <Menu delay={delay + 1800}>
        <Box blurb={projectsBlurb} link="/projects" title="Projects" />
        <Box blurb={aboutBlurb} title="About me" link="/about" />
        <Box blurb={backgroundBlurb} title="Background" />
        <Box blurb="Find me on the internet" title="Contact" />
      </Menu>
    </Layout>
  );
};

export default IndexPage;
