import * as React from 'react';
import PropTypes from 'prop-types';
import { bordered, menu } from './menu.module.scss';
import Delay from '../Delay';

const Menu = ({ children, delay = 0 }) => {
  return (
    <section className={menu}>
      <Delay delay={delay}>
        <div className={bordered}>{children}</div>
      </Delay>
    </section>
  );
};

Menu.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  delay: PropTypes.number,
};

export default Menu;
