import * as React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { box, welcome } from './box.module.scss';

const Box = ({ link = '#', title, blurb = '' }) => {
  return (
    <Link to={link} className={box}>
      <h2>{title}</h2>
      <p>{blurb}</p>
      <p className={welcome}>
        <span>See more</span>
      </p>
    </Link>
  );
};

Box.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string,
};

export default Box;
